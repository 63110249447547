import {
	AppBar,
	Avatar,
	Box,
	Button,
	Grid,
	IconButton,
	TextField,
	Toolbar,
	Typography,
} from '@mui/material';
import React from 'react';
import useStyles from '../styles/contact_styles';
import { useForm, Controller } from 'react-hook-form';
import { ArrowBack, Email, Phone } from '@mui/icons-material';
import Footer from '../components/Footer';
import { Link } from 'gatsby';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';

const Contact = () => {
	const contact_classes = useStyles();
	const { reset, control } = useForm();
	const form = React.useRef();

	const onSubmit = (e) => {
		e.preventDefault();
		emailjs
			.sendForm('service_mcemejc', 'template_w9bsmgu', form.current, 'Z-cYOn7iBBJlnN0Zu')
			.then(
				(result) => {
					console.log(result.text);
					reset();
				},
				(error) => {
					console.log(error.text);
				}
			);
	};

	const contacts = [
		{
			icon: <Phone sx={{ color: '#F49C34', transform: 'scale(1.8)' }} />,
			title: 'Call Us',
			description: 'We are eager to hear your voice. get in touch!',
			contact1: '+27 81 489 7012',
			contact2: '+27 88 048 033',
		},
		{
			icon: <Email sx={{ color: '#F49C34', transform: 'scale(1.8)' }} />,
			title: 'Email Us',
			description: 'Communicate with us via our email, we would love to hear from you.',
			contact1: 'admin@risingsun.org.za',
			contact2: 'memeva@risingsun.org.za',
		},
	];

	return (
		<Box>
			<Helmet>
				<link rel='icon' href='rising_sun3.ico' />
			</Helmet>
			<AppBar position='relative' color='transparent' sx={{ zIndex: 1, boxShadow: 0 }}>
				<Toolbar>
					<Link to='/'>
						<IconButton
							size='large'
							edge='start'
							aria-label='home'
							// href='/'
							sx={{ mr: 2, color: '#F49C34' }}
						>
							<ArrowBack />
						</IconButton>
					</Link>
					<Link to='/'>
						<Avatar
							src='rising_sun2.png'
							alt='Rising Sun Foundation Logo'
							variant='rounded'
							sx={{
								width: '200px',
								height: '100%',
							}}
						/>
					</Link>
				</Toolbar>
			</AppBar>
			<Box className={contact_classes.hero}>
				<Box
					className={contact_classes.overlay}
					sx={{
						alignItems: { xs: 'center' },
						justifyContent: { xs: 'flex-end', md: 'space-around' },
						flexDirection: { xs: 'column', md: 'row' },
						marginBottom: { xs: 1, md: 0 },
					}}
				>
					<Typography variant='h4' fontWeight={800} sx={{ color: '#F49C34' }}>
						CONTACT US
					</Typography>
					<form className={contact_classes.form} ref={form} onSubmit={onSubmit}>
						<Controller
							name={'name'}
							control={control}
							className={contact_classes.controller}
							render={({ field: { onChange, value } }) => (
								<TextField
									onChange={onChange}
									value={value}
									variant='standard'
									label={'Name'}
									name='name'
									sx={{
										m: '10px 0px',
										input: { color: '#F5F1E8' },
									}}
									className={contact_classes.textField}
									inputProps={{
										className: contact_classes.input,
									}}
								/>
							)}
						/>
						<Controller
							name={'email'}
							control={control}
							className={contact_classes.controller}
							render={({ field: { onChange, value } }) => (
								<TextField
									required
									onChange={onChange}
									value={value}
									type='email'
									variant='standard'
									label={'Email'}
									name='email'
									sx={{
										m: '10px 0px',
										input: { color: '#F5F1E8' },
									}}
									className={contact_classes.textField}
									inputProps={{
										className: contact_classes.input,
									}}
								/>
							)}
						/>
						<Controller
							name={'message'}
							control={control}
							className={contact_classes.controller}
							render={({ field: { onChange, value } }) => (
								<TextField
									onChange={onChange}
									value={value}
									variant='standard'
									label={'Message'}
									name='message'
									required
									sx={{
										m: '10px 0px',
										input: { color: '#F5F1E8' },
									}}
									className={contact_classes.textField}
									inputProps={{
										className: contact_classes.input,
									}}
								/>
							)}
						/>
						<Button
							// onClick={handleSubmit(onSubmit)}
							type='submit'
							className={contact_classes.sendButton}
							variant='contained'
							size='medium'
							sx={{
								'color': '#F49C34',
								'width': 100,
								'borderRadius': 2,
								'backgroundColor': '#F5F1E8',
								'border': '1px solid #F49C34',
								'mt': '10px',
								':hover': {
									backgroundColor: 'rgba(245,241,232, 0.8)',
								},
							}}
						>
							Send
						</Button>
					</form>
				</Box>
			</Box>
			<Grid
				container
				spacing={2}
				className={contact_classes.grid}
				sx={{ backgroundSize: { xs: '25% 50%', md: '10% 75%' } }}
			>
				{contacts.map(({ icon, title, description, contact1, contact2 }, i) => (
					<Grid
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							mb: 10,
						}}
						item
						xs={12}
						md={6}
					>
						<IconButton disabled>{icon}</IconButton>
						<Typography
							variant='subtitle1'
							sx={{
								textTransform: 'uppercase',
								fontWeight: 800,
								color: 'rgba(0, 0, 0, 0.7)',
							}}
						>
							{title}
						</Typography>
						<Typography
							variant='subtitle2'
							sx={{ textTransform: 'capitalize', fontWeight: 50 }}
						>
							{description}
						</Typography>
						<Typography
							variant='subtitle1'
							sx={{ textTransform: 'lowercase', color: '#F49C34' }}
						>
							{contact1}
						</Typography>
						<Typography
							variant='subtitle1'
							sx={{ textTransform: 'lowercase', color: '#F49C34' }}
						>
							{contact2}
						</Typography>
					</Grid>
				))}
			</Grid>
			<Box margin={70}></Box>
			<Footer />
		</Box>
	);
};

export default Contact;
